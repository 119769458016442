import {
  CreateOrganisationRequest,
  ExtendedOrganisation,
  ExtendedThalamosUser,
  formValidationErrorMessages,
  GetOrganisationResponse,
  isGuestUser,
  mergeConfigurations,
  nonAdmissionTemplates,
  nonEmptyString,
  OrganisationConfiguration,
  OrganisationMembership,
  OrgTree,
  postalCodeValidationRule,
  TemplateData,
  UUID,
} from "@aspire/common";
import { css } from "@emotion/react";
import {
  CloudDownload,
  FiberManualRecord,
  Groups,
  Info,
  PlayArrow,
  Visibility,
} from "@mui/icons-material";
import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  IconButton,
  Paper,
  Stack,
  styled,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { debounce, isEqual } from "lodash-es";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { array, bool, boolean, object, string } from "yup";
import {
  Button,
  Dropdown,
  MenuFlyout,
  MenuOptionsType,
  renderErrorToast,
  renderSuccessToast,
  TextField,
  Typeahead,
} from "~/components/design-system/index.js";
import {
  ConfirmationCheckboxFormField,
  DropdownFormField,
  NameAddressFormField,
  TextboxFormField,
} from "~/components/form/index.js";
import { getRoleLabel } from "~/util.js";
import { api, apiHooks } from "../../api.js";
import { routeFns } from "../../routes.js";
import { ConfirmationModal } from "../ConfirmationModal.js";
import { addOrgMemberValues } from "./constants.js";
import { ManageOrganisationUsers } from "./ManagingOrganisationUsers/ManageOrganisationUsers.js";
import { OrganisationPicker } from "./OrganisationPicker.js";

const orgDetailsSchema = object({
  name: nonEmptyString
    .default("")
    .required("Please enter a name")
    .trim()
    .max(255, formValidationErrorMessages.tooManyCharactersError),

  address: object({
    address: nonEmptyString
      .required("Address is required")
      .trim()
      .max(255, formValidationErrorMessages.tooManyCharactersError),
    postalCode: postalCodeValidationRule("role", "hospital"),
    isConfirmed: bool().required().isTrue("Please confirm your address"),
  }).default({
    address: "",
    isConfirmed: false,
    postalCode: "",
  }),

  role: nonEmptyString
    .default(null)
    // @ts-ignore
    .nonNullable("Please enter a role"),

  roleName: string().default(""),

  isTrainingOrganisation: boolean().default(false),
  isUserManagementOrganisation: boolean().default(false),
  hasPatientMergeAccess: boolean().default(false),

  isOwnDataController: boolean().default(false),
  dataControllerId: nonEmptyString.default(null).when("isOwnDataController", {
    is: false,
    then: (s) =>
      nonEmptyString
        .required()
        // @ts-ignore
        .nonNullable("Please enter a data controller ID"),
    otherwise: (s) => nonEmptyString.default(null),
  }),

  dataControllerName: string().default(""),

  hasManagingOrganisation: boolean().default(true),

  managingOrganisationId: nonEmptyString
    .default(null)
    .when("hasManagingOrganisation", {
      is: true,
      then: (s) =>
        nonEmptyString
          .required()
          // @ts-ignore
          .nonNullable("Please enter a managing organisation"),
      otherwise: (s) => nonEmptyString.default(null),
    }),
  managingOrganisationName: string().default(""),

  // Don't allow to set ODS yet?
  codes: array()
    .of(
      object({
        system: nonEmptyString.required("System is required"),
        code: nonEmptyString.required("Code is required"),
      }),
    )
    .default([]),
});

type OrgDataType = Omit<CreateOrganisationRequest, "dataControllerId"> & {
  dataControllerId: string | null;
  dataControllerName: string | undefined;
  managingOrganisationName: string | undefined;
  hasManagingOrganisation: boolean;
  isOwnDataController: boolean;
  roleName: string;
};

const orgRoles = [
  { label: "NHS Trust", value: "nhs/trust" },
  { label: "Hospital", value: "hospital" },
  { label: "Local Authority", value: "nhs/local-authority" },
  { label: "NHS ICB", value: "nhs/icb" },
  { label: "NHS Commissioning Region", value: "nhs/commissioning-region" },
];

function DeleteOrgButton({
  organisation,
}: {
  organisation: ExtendedOrganisation;
}) {
  const [confirmFn, setConfirmFn] = React.useState<{
    confirmFn: () => void;
    message: string;
  } | null>(null);

  const navigate = useNavigate();

  return (
    <>
      {confirmFn && (
        <ConfirmationModal
          message={confirmFn.message}
          confirmFn={confirmFn.confirmFn}
          closeFn={() => setConfirmFn(null)}
        />
      )}

      <Button
        onClick={() => {
          setConfirmFn({
            message: `Are you sure you want to delete this organisation (${organisation.name})? This cannot be undone. The organisation must have no child organisations, teams or members, and cannot be the data controller for another org.`,
            confirmFn: async () => {
              const result = await api.organisations.delete(organisation.id, {
                reason: "created-in-error",
              });

              if (result.status === 204) {
                navigate(routeFns.adminOrganisationsPage());
                renderSuccessToast({
                  message: "Organisation deleted successfully",
                });
              } else {
                renderErrorToast({
                  message: `${result.data.reason}`,
                });
              }
            },
          });
        }}
        sx={{ ml: "1em" }}
        color={"error"}
        label={"Delete"}
      />
    </>
  );
}

function OrganisationCreateEditForm({
  organisation,
  organisationId,
  refetchOrg,
}: {
  organisation: ExtendedOrganisation | null;
  organisationId: string;
  refetchOrg: () => void;
}) {
  const isEditableOrg =
    !organisation || orgRoles.map((r) => r.value).includes(organisation.role);

  const initialData = {
    ...orgDetailsSchema.getDefault(),
    ...(organisation
      ? {
          ...organisation,
          hasPatientMergeAccess: organisation.hasPatientMergeAccess || false,
          isTrainingOrganisation: organisation.isTrainingOrganisation || false,
          isUserManagementOrganisation:
            organisation.isUserManagementOrganisation ?? false,
          roleName:
            orgRoles.find((r) => r.value === organisation.role)?.label ||
            organisation.role,
          managingOrganisationName: organisation.managingOrganisationName || "",
          hasManagingOrganisation: !!organisation.managingOrganisationId,
          isOwnDataController: organisation.dataControllerId === organisationId,
          address: {
            address: organisation.address,
            postalCode: organisation.postalCode,
            isConfirmed: true,
          },
        }
      : {}),
  };

  return isEditableOrg ? (
    <Formik<OrgDataType>
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        const cleanedOrgData = {
          name: values.name,
          address: {
            address: values.address.address,
            postalCode: values.address.postalCode,
            isConfirmed: true,
          },
          role: values.role,
          hasPatientMergeAccess: values.hasPatientMergeAccess,
          isTrainingOrganisation: values.isTrainingOrganisation,
          dataControllerId: values.isOwnDataController
            ? organisationId
            : values.dataControllerId!,
          managingOrganisationId: values.hasManagingOrganisation
            ? values.managingOrganisationId
            : null,
          codes: organisation?.codes || [],
          isUserManagementOrganisation: values.isUserManagementOrganisation,
        };

        if (organisation) {
          const result = await api.organisations.update(
            organisationId,
            cleanedOrgData,
          );

          if (result.status === 204) {
            refetchOrg();
            renderSuccessToast({
              message: "Organisation updated successfully",
            });
          } else {
            renderErrorToast({
              message: `Organisation update failed: ${result.data}`,
            });
          }
        } else {
          const result = await api.organisations.create(
            organisationId,
            cleanedOrgData,
          );

          if (result.status === 204) {
            refetchOrg();
            renderSuccessToast({
              message: "Organisation created successfully",
            });
          } else {
            renderErrorToast({
              message: `Organisation create failed: ${result.data}`,
            });
          }
        }
        setSubmitting(false);
      }}
      validationSchema={orgDetailsSchema}
      initialValues={initialData as OrgDataType}
    >
      {(formikData) => {
        const {
          values,
          setValues,
          setFieldTouched,
          errors,
          touched,
          handleBlur,
          submitForm,
        } = formikData;

        const fieldProps = {
          validationSchema: orgDetailsSchema,
          values,
          setValues,
          setFieldTouched,
          errors,
          touched,
          context: {},
          handleBlur,
        };

        return (
          <>
            <Box sx={{ mb: 0 }}>
              <TextboxFormField
                field={{ type: "textbox", label: "Name (*)", field: "name" }}
                fieldProps={fieldProps}
              />
            </Box>
            <Box sx={{ mb: 1 }}>
              <NameAddressFormField
                field={{
                  type: "name-address",
                  disableEmail: true,
                  disableName: true,
                  field: "address",
                  addressLabel: "Address (*)",
                }}
                fieldProps={fieldProps}
              />
            </Box>
            <Box sx={{ mb: 1 }}>
              <ConfirmationCheckboxFormField
                field={{
                  type: "confirmation-checkbox",
                  label: "",
                  field: "hasManagingOrganisation",
                  checkboxLabel:
                    "Does this organisation have a managing organisation (it normally will)?",
                }}
                fieldProps={fieldProps}
              />
              {values.hasManagingOrganisation && (
                <OrganisationPicker
                  label={"Managing Organisation (*)"}
                  error={
                    touched.managingOrganisationId
                      ? (errors.managingOrganisationId as string)
                      : null
                  }
                  idField={"managingOrganisationId"}
                  nameField={"managingOrganisationName"}
                  values={values}
                  setValues={setValues}
                />
              )}
            </Box>
            <Box sx={{ mb: 1 }}>
              <DropdownFormField
                field={{
                  type: "dropdown",
                  label: "Role (*)",
                  valueField: "role",
                  textField: "roleName",
                  options: orgRoles,
                }}
                fieldProps={fieldProps}
              />
            </Box>
            <Box sx={{ mb: 1 }}>
              <ConfirmationCheckboxFormField
                field={{
                  type: "confirmation-checkbox",
                  label: "",
                  field: "isOwnDataController",
                  checkboxLabel:
                    "Is this organisation its own data controller?",
                }}
                fieldProps={fieldProps}
              />
              <OrganisationPicker
                label={"Data Controller (*)"}
                error={
                  touched.dataControllerId
                    ? (errors.dataControllerId as string)
                    : null
                }
                idField={"dataControllerId"}
                nameField={"dataControllerName"}
                values={values}
                setValues={setValues}
                disabled={values.isOwnDataController}
              />
            </Box>
            <Box sx={{ mb: 1 }}>
              <FormControlLabel
                control={
                  <Switch
                    onChange={() =>
                      setValues({
                        ...values,
                        isTrainingOrganisation: !values.isTrainingOrganisation,
                      })
                    }
                    checked={values.isTrainingOrganisation}
                    disabled={!values.name.toLowerCase().includes("training")}
                  />
                }
                label={
                  "Training Org (Can only be enabled if the name of the organisation includes 'training')"
                }
              />
            </Box>
            <Box sx={{ mb: 1 }}>
              <FormControlLabel
                control={
                  <Switch
                    onChange={() =>
                      setValues({
                        ...values,
                        isUserManagementOrganisation:
                          !values.isUserManagementOrganisation,
                      })
                    }
                    checked={values.isUserManagementOrganisation}
                  />
                }
                label={
                  "User Management Org (Does this organisation 'manage' the Users in teams and organisations below it in the organisation hierarchy) "
                }
              />
            </Box>

            <Box sx={{ mb: 1 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={values.hasPatientMergeAccess}
                    onChange={() =>
                      setValues({
                        ...values,
                        hasPatientMergeAccess: !values.hasPatientMergeAccess,
                      })
                    }
                  />
                }
                label={"Patient Merge Enabled"}
              />
            </Box>
            <Box sx={{ mb: 2, mt: 1 }}>
              <Button
                label={organisation ? "Update" : "Create"}
                onClick={submitForm}
              />
              {organisation && <DeleteOrgButton organisation={organisation} />}
            </Box>
          </>
        );
      }}
    </Formik>
  ) : (
    <Box>
      This organisation (role: {organisation.role}, address:{" "}
      {organisation?.address} {organisation?.postalCode}) cannot be edited in
      the admin UI. Please talk to the dev team about modifying it.
    </Box>
  );
}

function DataControllerInfo({
  organisationData,
  organisation,
}: {
  organisationData: GetOrganisationResponse;
  organisation: ExtendedOrganisation;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <h3>Orgs in this data controller</h3>
      <TableContainer sx={{ width: "100%" }}>
        <TableRow>
          <TableCell>
            <strong>{t("common.name")}</strong>
          </TableCell>
        </TableRow>
        {organisationData &&
          (organisation?.orgsInThisDataController || []).map((org) => {
            return (
              <TableRow>
                <TableCell>{org.name}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={() =>
                      navigate(routeFns.organisationCreateEdit(org.id))
                    }
                  >
                    <Visibility />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
      </TableContainer>
    </>
  );
}

function ChildOrgsAndTeamsEdit({
  organisationData,
  organisation,
}: {
  organisationData: GetOrganisationResponse;
  organisation: ExtendedOrganisation;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <h3>Child Orgs</h3>
      <TableContainer sx={{ width: "100%" }}>
        <TableRow>
          <TableCell>
            <strong>{t("common.name")}</strong>
          </TableCell>
        </TableRow>
        {organisationData &&
          (organisation?.childOrganisations || []).map((org) => {
            return (
              <TableRow>
                <TableCell>{org.name}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={() =>
                      navigate(routeFns.organisationCreateEdit(org.id))
                    }
                  >
                    <Visibility />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
      </TableContainer>

      <h3>Teams</h3>
      <TableContainer sx={{ width: "100%" }}>
        <TableRow>
          <TableCell>
            <strong>{t("common.name")}</strong>
          </TableCell>
          <TableCell>
            <strong>Type</strong>
          </TableCell>
        </TableRow>
        {organisationData &&
          (organisation?.teams || []).map((team) => {
            return (
              <TableRow>
                <TableCell>{team.name}</TableCell>
                <TableCell>{team.type}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => navigate(routeFns.teamCreateEdit(team.id))}
                  >
                    <Visibility />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
      </TableContainer>
    </>
  );
}

function OverrideableBooleanConfiguration({
  field,
  label,
  inheritedConfig,
  configuration,
  setConfiguration,
}: {
  field: keyof OrganisationConfiguration;
  label: string;
  inheritedConfig: Partial<OrganisationConfiguration>;
  configuration: Partial<OrganisationConfiguration>;
  setConfiguration: (config: Partial<OrganisationConfiguration>) => void;
}) {
  return (
    <TableRow>
      <TableCell>{label}</TableCell>
      <TableCell>
        <FormControlLabel
          control={
            <Switch
              checked={configuration[field] !== undefined}
              onChange={() => {
                if (configuration[field] === undefined) {
                  setConfiguration({
                    ...configuration,
                    [field]: inheritedConfig[field],
                  });
                } else {
                  // Remove the key entirely, rather than just setting to undefined
                  const temp = { ...configuration };
                  delete temp[field];
                  setConfiguration({
                    ...temp,
                  });
                }
              }}
            />
          }
          label={`Override`}
        />
      </TableCell>
      <TableCell>
        <FormControlLabel
          control={
            <Checkbox
              disabled={configuration[field] === undefined}
              checked={
                configuration[field] === undefined
                  ? (inheritedConfig[field] as boolean)
                  : (configuration[field] as boolean)
              }
              onChange={() =>
                setConfiguration({
                  ...configuration,
                  [field]: !configuration[field],
                })
              }
            />
          }
          label={"Enable Feature"}
        />
      </TableCell>
    </TableRow>
  );
}

function OverrideableFormTemplateConfiguration({
  field,
  label,
  inheritedConfig,
  configuration,
  setConfiguration,
}: {
  field: keyof OrganisationConfiguration;
  label: string;
  inheritedConfig: Partial<OrganisationConfiguration>;
  configuration: Partial<OrganisationConfiguration>;
  setConfiguration: (config: Partial<OrganisationConfiguration>) => void;
}) {
  const isSet = configuration[field] !== undefined;

  return (
    <TableRow>
      <TableCell>{label}</TableCell>
      <TableCell>
        <FormControlLabel
          control={
            <Switch
              checked={isSet}
              onChange={() => {
                if (!isSet) {
                  setConfiguration({
                    ...configuration,
                    [field]: inheritedConfig[field],
                  });
                } else {
                  // Remove the key entirely, rather than just setting to undefined
                  const temp = { ...configuration };
                  delete temp[field];
                  setConfiguration({
                    ...temp,
                  });
                }
              }}
            />
          }
          label={`Override`}
        />
      </TableCell>
      <TableCell>
        <Dropdown
          onChange={(values: string[]) => {
            const newTemplates = values
              .map((v) => ({
                id: v.split("*")[0],
                version: v.split("*")[1],
              }))
              .sort((a, b) => (a.id < b.id ? -1 : 1));
            setConfiguration({
              ...configuration,
              [field]: newTemplates,
            });
          }}
          disabled={!isSet}
          name={"formTemplates"}
          selectedValue={(isSet
            ? configuration.enabledForms || []
            : inheritedConfig.enabledForms || []
          ).map((f) => `${f.id}*${f.version}`)}
          multiple
          values={nonAdmissionTemplates
            .sort((a, b) => (a.id < b.id ? -1 : 1))
            .map((t) => ({
              value: `${t.id}*${t.version}`,
              label: `${t.formName} (${t.version})`,
            }))}
        />
      </TableCell>
    </TableRow>
  );
}

function OverrideableTermsAndConditionsConfiguration({
  field,
  label,
  inheritedConfig,
  configuration,
  setConfiguration,
}: {
  field: keyof OrganisationConfiguration;
  label: string;
  inheritedConfig: Partial<OrganisationConfiguration>;
  configuration: Partial<OrganisationConfiguration>;
  setConfiguration: (config: Partial<OrganisationConfiguration>) => void;
}) {
  const isSet = configuration[field] !== undefined;

  const [{ data, loading: loadingTermsAndConditions, error }] =
    apiHooks.termsAndConditions.getAll();

  const termsAndConditions = data?.termsAndConditions || [];
  return (
    <TableRow>
      <TableCell>{label}</TableCell>
      <TableCell>
        <FormControlLabel
          control={
            <Switch
              disabled={error !== null}
              checked={isSet}
              onChange={() => {
                if (!isSet) {
                  setConfiguration({
                    ...configuration,
                    [field]: inheritedConfig[field],
                  });
                } else {
                  // Remove the key entirely, rather than just setting to undefined
                  const temp = { ...configuration };
                  delete temp[field];
                  setConfiguration({
                    ...temp,
                  });
                }
              }}
            />
          }
          label={`Override`}
        />
      </TableCell>
      <TableCell>
        {error ? (
          <p>Unable to update terms and conditions, try again later</p>
        ) : loadingTermsAndConditions ? (
          <CircularProgress />
        ) : (
          <Dropdown
            onChange={(value: string) => {
              const matched = termsAndConditions.find((t) => t.id === value);

              setConfiguration({
                ...configuration,
                [field]: {
                  id: matched?.id,
                  humanReadableName: matched?.humanReadableName,
                },
              });
            }}
            disabled={!isSet}
            name={"termsAndConditions"}
            selectedValue={
              isSet ? (configuration.termsAndConditions?.id ?? "") : ""
            }
            values={termsAndConditions.map((t) => ({
              value: `${t.id}`,
              label: `${t.humanReadableName}`,
            }))}
          />
        )}
      </TableCell>
    </TableRow>
  );
}

function OverrideableRioInstancesConfiguration({
  field,
  label,
  inheritedConfig,
  configuration,
  setConfiguration,
}: {
  field: keyof OrganisationConfiguration;
  label: string;
  inheritedConfig: Partial<OrganisationConfiguration>;
  configuration: Partial<OrganisationConfiguration>;
  setConfiguration: (config: Partial<OrganisationConfiguration>) => void;
}) {
  const isSet = configuration[field] !== undefined;

  const rioInstancesPageSize = 100;
  const page = 0;
  const [{ data, loading: loadingRioInstances, error }] = apiHooks.rio.search(
    "",
    rioInstancesPageSize,
    page * rioInstancesPageSize,
  );

  const rioInstances = data?.results || [];
  return (
    <TableRow>
      <TableCell>{label}</TableCell>
      <TableCell>
        <FormControlLabel
          control={
            <Switch
              disabled={error !== null}
              checked={isSet}
              onChange={() => {
                if (!isSet) {
                  setConfiguration({
                    ...configuration,
                    [field]: inheritedConfig[field],
                  });
                } else {
                  // Remove the key entirely, rather than just setting to undefined
                  const temp = { ...configuration };
                  delete temp[field];
                  setConfiguration({
                    ...temp,
                  });
                }
              }}
            />
          }
          label={`Override`}
        />
      </TableCell>
      <TableCell>
        {error ? (
          <p>Unable to update rio instances, try again later</p>
        ) : loadingRioInstances ? (
          <CircularProgress />
        ) : (
          <Dropdown
            onChange={(values: string[]) => {
              const newTemplates = values
                .map((v) => ({
                  instanceId: v.split("*")[0],
                  displayName: v.split("*")[1],
                }))
                .sort((a, b) => (a.displayName < b.displayName ? -1 : 1));
              setConfiguration({
                ...configuration,
                [field]: newTemplates,
              });
            }}
            disabled={!isSet}
            name={"rioInstances"}
            selectedValue={(isSet
              ? configuration.rioInstancesEnabled || []
              : inheritedConfig.rioInstancesEnabled || []
            ).map((f) => `${f.instanceId}*${f.displayName}`)}
            multiple
            values={rioInstances
              .sort((a, b) => (a.displayName < b.displayName ? -1 : 1))
              .map((t) => ({
                value: `${t.instanceId}*${t.displayName}`,
                label: `${t.displayName}`,
              }))}
          />
        )}
      </TableCell>
    </TableRow>
  );
}

function OverrideableTextFieldConfiguration({
  field,
  label,
  inheritedConfig,
  configuration,
  setConfiguration,
  valueTransformer,
}: {
  field: keyof OrganisationConfiguration;
  label: string;
  inheritedConfig: Partial<OrganisationConfiguration>;
  configuration: Partial<OrganisationConfiguration>;
  setConfiguration: (config: Partial<OrganisationConfiguration>) => void;
  valueTransformer?: (value: string) => string;
}) {
  const isSet = configuration[field] !== undefined;
  return (
    <TableRow>
      <TableCell>{label}</TableCell>
      <TableCell>
        <FormControlLabel
          control={
            <Switch
              checked={isSet}
              onChange={() => {
                if (configuration[field] === undefined) {
                  setConfiguration({
                    ...configuration,
                    [field]: inheritedConfig[field],
                  });
                } else {
                  // Remove the key entirely, rather than just setting to undefined
                  const temp = { ...configuration };
                  delete temp[field];
                  setConfiguration({
                    ...temp,
                  });
                }
              }}
            />
          }
          label={`Override`}
        />
      </TableCell>
      <TableCell>
        <TextField
          name={field}
          value={
            configuration[field] === undefined
              ? (inheritedConfig[field] as string)
              : (configuration[field] as string)
          }
          disabled={!isSet}
          useFullWidth
          onChange={(value: string) => {
            setConfiguration({
              ...configuration,
              [field]: valueTransformer
                ? valueTransformer(value.trim())
                : value.trim(),
            });
          }}
        />
      </TableCell>
    </TableRow>
  );
}

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
  },
});

function OverrideableTemplateStringConfiguration({
  field,
  label,
  inheritedConfig,
  configuration,
  setConfiguration,
  multiline,
  rows,
}: {
  field: keyof OrganisationConfiguration;
  label: string;
  inheritedConfig: Partial<OrganisationConfiguration>;
  configuration: Partial<OrganisationConfiguration>;
  setConfiguration: (config: Partial<OrganisationConfiguration>) => void;
  multiline?: boolean;
  rows?: string;
}) {
  const isSet = configuration[field] !== undefined;
  const exampleFormData = {
    patient: {
      givenName: "Zane",
      familyName: "Bridges",
      dateOfBirth: new Date("2001-05-31 00:00:00").toISOString(),
    },
    form: {
      typeCode: "MHA",
      name: "H3",
      dateUpdated: new Date("2024-08-22 14:55:00").toISOString(),
      section: "2",
    },
    initialFilename: "filename.pdf",
    initialTitle: "Title",
  };

  const templateDataAvailable = [
    "patient.givenName",
    "patient.familyName",
    "patient.dateOfBirth",
    "form.typeCode",
    "form.name",
    "form.dateUpdated",
    "form.section",
    "initialFilename",
    "initialTitle",
  ];

  const templateModifiersAvailable = [
    "uppercase",
    "lowercase",
    "initial",
    "redact",
    "lastDigits",
    "dayMonthYear",
    "yearMonthDay",
    "dayMonthYearHyphen",
    "timeStamp",
  ];

  const [preview, setPreview] = useState<string>("");

  const getPreview = useCallback(
    debounce(async (template: string, templateData: TemplateData) => {
      const result = await api.templates.compileTemplate(
        template,
        templateData,
      );
      setPreview(result.data);
    }, 500),
    [],
  );

  useEffect(() => {
    if (isSet) {
      getPreview(configuration[field] as string, exampleFormData);
    } else {
      getPreview(inheritedConfig[field] as string, exampleFormData);
    }
  }, []);

  return (
    <TableRow>
      <TableCell>{label}</TableCell>
      <TableCell>
        <Stack direction="row">
          <FormControlLabel
            control={
              <Switch
                checked={isSet}
                onChange={() => {
                  if (configuration[field] === undefined) {
                    setConfiguration({
                      ...configuration,
                      [field]: inheritedConfig[field],
                    });
                  } else {
                    // Remove the key entirely, rather than just setting to undefined
                    const temp = { ...configuration };
                    delete temp[field];
                    setConfiguration({
                      ...temp,
                    });
                    // When unchecked set the preview back to default
                    getPreview(
                      inheritedConfig[field] as string,
                      exampleFormData,
                    );
                  }
                }}
              />
            }
            label={`Override`}
          />
          <NoMaxWidthTooltip
            placement="left"
            title={
              <Stack>
                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={2}
                >
                  <Stack>
                    <Typography fontWeight="bold">Data available</Typography>
                    {templateDataAvailable.map((data, index) => {
                      return <Typography key={index}>{data}</Typography>;
                    })}
                  </Stack>
                  <Stack>
                    <Typography fontWeight="bold">
                      Modifiers available
                    </Typography>
                    {templateModifiersAvailable.map((modifier, index) => {
                      return <Typography key={index}>{modifier}</Typography>;
                    })}
                  </Stack>
                </Stack>
                <Typography>
                  {"Example use: {{initial patient.familyName}}"}
                </Typography>
              </Stack>
            }
          >
            <IconButton>
              <Info />
            </IconButton>
          </NoMaxWidthTooltip>
        </Stack>
      </TableCell>
      <TableCell>
        <TextField
          name={field}
          multiline={multiline}
          rows={rows}
          value={
            configuration[field] === undefined
              ? (inheritedConfig[field] as string)
              : (configuration[field] as string)
          }
          disabled={!isSet}
          useFullWidth
          onChange={(value: string) => {
            setConfiguration({
              ...configuration,
              [field]: value,
            });
            getPreview(value, exampleFormData);
          }}
        />
        <Box>
          {preview &&
            preview
              .split("\n")
              .map((line, index) => (
                <Typography key={index}>{line}</Typography>
              ))}
        </Box>
      </TableCell>
    </TableRow>
  );
}

function OrganisationConfigurationEdit({
  organisationData,
  refetchOrg,
}: {
  organisationData: GetOrganisationResponse;
  refetchOrg: () => void;
}) {
  const inheritedOrganisationHierarchy =
    organisationData.configurationHierarchy.filter(
      (o) => o.organisationId !== organisationData.organisation.id,
    );

  const inheritedConfig = mergeConfigurations(
    organisationData.organisation.managingOrganisationId,
    inheritedOrganisationHierarchy,
  );

  const orgConfig = organisationData.configurationHierarchy.find(
    (o) => o.organisationId === organisationData.organisation.id,
  )!.configuration;

  const [configuration, setConfiguration] =
    useState<Partial<OrganisationConfiguration>>(orgConfig);

  return (
    <>
      <Box sx={{ mb: 3 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell>Override Inherited Value</TableCell>
                <TableCell>State</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <OverrideableBooleanConfiguration
                label={"Enable Advance Choice Documents"}
                field={"acdEnabled"}
                inheritedConfig={inheritedConfig}
                configuration={configuration}
                setConfiguration={setConfiguration}
              />
              <OverrideableBooleanConfiguration
                label={
                  "Exclude Administrative Area from Google Autosuggest Addresses"
                }
                field={"excludeAdministrativeAreaFromAddress"}
                inheritedConfig={inheritedConfig}
                configuration={configuration}
                setConfiguration={setConfiguration}
              />
              <OverrideableBooleanConfiguration
                label={"Enable MHA Admissions"}
                field={"mhaAdmissionsEnabled"}
                inheritedConfig={inheritedConfig}
                configuration={configuration}
                setConfiguration={setConfiguration}
              />
              <OverrideableBooleanConfiguration
                label={"Enable Nhs Number"}
                field={"nhsNumberEnabled"}
                inheritedConfig={inheritedConfig}
                configuration={configuration}
                setConfiguration={setConfiguration}
              />
              <OverrideableBooleanConfiguration
                label={"Enable Search v2"}
                field={"searchV2Enabled"}
                inheritedConfig={inheritedConfig}
                configuration={configuration}
                setConfiguration={setConfiguration}
              />
              <OverrideableBooleanConfiguration
                label={"Enable Merge v2"}
                field={"mergeV2Enabled"}
                inheritedConfig={inheritedConfig}
                configuration={configuration}
                setConfiguration={setConfiguration}
              />
              <OverrideableBooleanConfiguration
                label={"Enable MHA Admissions Joint Medical Recommendations"}
                field={"mhaAdmissionsJointMedRecsEnabled"}
                inheritedConfig={inheritedConfig}
                configuration={configuration}
                setConfiguration={setConfiguration}
              />
              <OverrideableBooleanConfiguration
                label={"Uploadable Forms (for admissions)"}
                field={"uploadFormsEnabled"}
                inheritedConfig={inheritedConfig}
                configuration={configuration}
                setConfiguration={setConfiguration}
              />
              <OverrideableBooleanConfiguration
                label={"Patient State"}
                field={"patientStateEnabled"}
                inheritedConfig={inheritedConfig}
                configuration={configuration}
                setConfiguration={setConfiguration}
              />
              <OverrideableBooleanConfiguration
                label={"Patient State CSV Report"}
                field={"mhaStatusReportEnabled"}
                inheritedConfig={inheritedConfig}
                configuration={configuration}
                setConfiguration={setConfiguration}
              />
              <OverrideableBooleanConfiguration
                label={"Patient Search Record Reasons"}
                field={"patientSearchRecordReasonsEnabled"}
                inheritedConfig={inheritedConfig}
                configuration={configuration}
                setConfiguration={setConfiguration}
              />
              <OverrideableTextFieldConfiguration
                label={
                  "PDS End User Organisation ODS Code (must be set to enable PDS)"
                }
                field={"pdsEndUserOrganisationODSCode"}
                inheritedConfig={inheritedConfig}
                configuration={configuration}
                setConfiguration={setConfiguration}
                valueTransformer={(value) => value.toUpperCase()}
              />
              <OverrideableTextFieldConfiguration
                label={"Demographics Management Email Address"}
                field={"patientDemographicsAlertEmail"}
                inheritedConfig={inheritedConfig}
                configuration={configuration}
                setConfiguration={setConfiguration}
              />
              <OverrideableBooleanConfiguration
                label={"NHS Person Demographics Service"}
                field={"pdsEnabled"}
                inheritedConfig={inheritedConfig}
                configuration={configuration}
                setConfiguration={setConfiguration}
              />
              <OverrideableBooleanConfiguration
                label={"Phonetic Patient Index Search"}
                field={"phoneticPatientIndexSearchEnabled"}
                inheritedConfig={inheritedConfig}
                configuration={configuration}
                setConfiguration={setConfiguration}
              />
              <OverrideableFormTemplateConfiguration
                label={"Form Templates (excl. admissions)"}
                field={"enabledForms"}
                inheritedConfig={inheritedConfig}
                configuration={configuration}
                setConfiguration={setConfiguration}
              />
              <OverrideableRioInstancesConfiguration
                label={"Rio Instances"}
                field={"rioInstancesEnabled"}
                inheritedConfig={inheritedConfig}
                configuration={configuration}
                setConfiguration={setConfiguration}
              />
              <OverrideableTextFieldConfiguration
                label={"Help Page Url"}
                field={"helpPageUrl"}
                inheritedConfig={inheritedConfig}
                configuration={configuration}
                setConfiguration={setConfiguration}
              />
              <OverrideableTextFieldConfiguration
                label={"Support Page Url"}
                field={"supportPageUrl"}
                inheritedConfig={inheritedConfig}
                configuration={configuration}
                setConfiguration={setConfiguration}
              />
              <OverrideableTemplateStringConfiguration
                label={"Rio Upload Filename Template"}
                field={"rioUploadFilenameTemplate"}
                inheritedConfig={inheritedConfig}
                configuration={configuration}
                setConfiguration={setConfiguration}
              />
              <OverrideableTemplateStringConfiguration
                label={"Rio Upload Title Template"}
                field={"rioUploadTitleTemplate"}
                inheritedConfig={inheritedConfig}
                configuration={configuration}
                setConfiguration={setConfiguration}
              />
              <OverrideableTemplateStringConfiguration
                label={"Rio Upload Description Template"}
                field={"rioUploadDescriptionTemplate"}
                inheritedConfig={inheritedConfig}
                configuration={configuration}
                setConfiguration={setConfiguration}
                multiline={true}
                rows={"3"}
              />
              <OverrideableTermsAndConditionsConfiguration
                label={"Terms and Conditions"}
                field={"termsAndConditions"}
                inheritedConfig={inheritedConfig}
                configuration={configuration}
                setConfiguration={setConfiguration}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Button
        label={"Update"}
        disabled={isEqual(configuration, orgConfig)}
        onClick={async () => {
          function isValidConfiguration(
            config: OrganisationConfiguration,
          ): string[] {
            const errors: string[] = [];

            if (config.searchV2Enabled) {
              if (!config.patientSearchRecordReasonsEnabled) {
                errors.push(
                  "Search V2 requires Patient Search Record Reasons to be enabled",
                );
              }
            }

            if (config.pdsEnabled) {
              if (!config.pdsEndUserOrganisationODSCode) {
                errors.push(
                  "PDS requires PDS End User Organisation ODS Code to be set",
                );
              }

              if (!config.searchV2Enabled) {
                errors.push("PDS requires Search V2 to be enabled");
              }

              if (!config.nhsNumberEnabled) {
                errors.push("PDS requires NHS Number to be enabled");
              }
            }

            if (config.phoneticPatientIndexSearchEnabled) {
              if (!config.searchV2Enabled) {
                errors.push("PDS requires Search V2 to be enabled");
              }
            }

            if (config.mergeV2Enabled) {
              if (!config.searchV2Enabled) {
                errors.push("Merge v2 requires Search V2 to be enabled");
              }
            }

            if (config.mhaStatusReportEnabled) {
              if (!config.patientStateEnabled) {
                errors.push(
                  "Patient State CSV Report requires Patient State to be enabled",
                );
              }
            }

            return errors;
          }

          const configErrors = isValidConfiguration({
            ...inheritedConfig,
            ...configuration,
          });

          if (configErrors.length > 0) {
            renderErrorToast({
              message: `Invalid configuration: ${configErrors.join(", ")}`,
            });
            return;
          }

          const result =
            await api.configuration.updateOrganisationConfiguration(
              organisationData.organisation.id,
              configuration,
            );

          if (result.status === 204) {
            renderSuccessToast({
              message: "Configuration updated successfully",
            });
            refetchOrg();
          } else if (result.status === 400) {
            renderErrorToast({
              message: `Error updating configuration: ${result.data.reason}`,
            });
          } else {
            renderErrorToast({ message: "Error updating configuration" });
          }
        }}
      />
    </>
  );
}

function OrgMemberRow({
  member,
  organisationId,
  refetchOrg,
}: {
  organisationId: string;
  member: {
    role: OrganisationMembership["role"];
    userId: UUID;
    userName: string;
    userEmail: string;
  };
  refetchOrg: () => void;
}) {
  const cardRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [confirmFn, setConfirmFn] = React.useState<{
    confirmFn: () => void;
    message: string;
  } | null>(null);

  const options: MenuOptionsType[] = [
    {
      name: "Remove from Org",
      onClick: () => {
        setConfirmFn({
          confirmFn: async () => {
            await api.organisations.removeMember(organisationId, {
              userId: member.userId,
              role: member.role,
            });

            refetchOrg();
          },
          message: `Are you sure you want to remove ${member.userName} from this organisation?`,
        });
      },
      icon: "delete",
      disabled: false,
    },
  ];

  return (
    <TableRow key={member.userId}>
      {confirmFn && (
        <ConfirmationModal
          message={confirmFn.message}
          confirmFn={confirmFn.confirmFn}
          closeFn={() => setConfirmFn(null)}
        />
      )}
      <TableCell>{member.userName}</TableCell>
      <TableCell>{member.userEmail}</TableCell>
      <TableCell>{getRoleLabel(member.role)}</TableCell>

      <TableCell>
        <Box ref={cardRef} onClick={() => setIsMenuOpen(true)}>
          <MenuFlyout
            cardRef={cardRef}
            options={options}
            isOpen={isMenuOpen}
            onClose={() => setIsMenuOpen(false)}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
}

function OrgMembersEdit({
  organisation,
  organisationId,
  refetchOrg,
}: {
  organisation: ExtendedOrganisation | null;
  organisationId: string;
  refetchOrg: () => void;
}) {
  const { t } = useTranslation();

  const [searchResults, setSearchResults] = React.useState<
    ExtendedThalamosUser[]
  >([]);

  const [userValue, setUserValue] = React.useState<
    string | ExtendedThalamosUser
  >("");

  const [selectedRole, setSelectedRole] = useState("");

  const handleRoleChange = async (role: OrganisationMembership["role"]) => {
    if (typeof userValue !== "string" && role) {
      await api.organisations.addMember(organisationId, {
        userId: userValue.id,
        role: role,
      });
      refetchOrg();
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <Typeahead
          css={css`
            width: 500px;
          `}
          label={"Search for a user to add"}
          options={searchResults}
          name="recipientEmail"
          enableFuse={false}
          inputValue={
            typeof userValue === "string" ? userValue : userValue?.email
          }
          optionsKey="name"
          getOptionLabel={(option) =>
            typeof option === "string"
              ? option
              : `${option.name} (${option.email})`
          }
          onInputChange={async (value: string) => {
            const match = searchResults.find(
              (u) => value === `${u.name} (${u.email})`,
            );
            if (match) {
              setUserValue(match);
            } else {
              setUserValue(value);
            }
            if (value.length > 1) {
              const results = await api.users.search(value, 10, 0);

              const filtered = results.data?.results.filter(
                (u) => !isGuestUser(u),
              );

              setSearchResults(filtered);

              if (match) {
                setUserValue(match);
              }
            } else {
              setSearchResults([]);
            }
          }}
        />
        <Box sx={{ marginLeft: "1em", width: "20%" }}>
          <Dropdown
            name="add-member-dropdown"
            values={addOrgMemberValues}
            selectedValue={selectedRole}
            onChange={(e) => {
              setSelectedRole(e);
              handleRoleChange(e);
            }}
            placeholder="Select role"
            disabled={typeof userValue === "string"}
          />
        </Box>
      </Box>

      <TableContainer sx={{ width: "100%" }}>
        <TableRow>
          <TableCell>
            <strong>{t("common.name")}</strong>
          </TableCell>
          <TableCell>
            <strong>{t("common.email")}</strong>
          </TableCell>
          <TableCell>
            <strong>{t("common.role")}</strong>
          </TableCell>
        </TableRow>
        {(organisation?.members || []).map((member) => {
          return (
            <OrgMemberRow
              member={member}
              organisationId={organisationId}
              refetchOrg={refetchOrg}
            />
          );
        })}
      </TableContainer>
    </>
  );
}

function OrganisationTree({ orgTree }: { orgTree: OrgTree }) {
  const dataControllerColors = [
    "#42d4f4", // cyan
    "#ffe119", // yellow
    "#3cb44b", // green
    "#f58231", // orange
    "#4363d8", // blue
    "#808000", // olive
    "#000075", // navy
    "#f032e6", // magenta
    "#e6194B", // red
    "#911eb4", // purple
    "#469990", // teal
    "#9A6324", // brown
    "#800000", // maroon
  ];

  const dataControllerToColor = new Map<string, string>();

  function getDataControllerColor(dataControllerId: string) {
    if (!dataControllerToColor.has(dataControllerId)) {
      dataControllerToColor.set(
        dataControllerId,
        dataControllerColors[dataControllerToColor.size],
      );
    }
    return dataControllerToColor.get(dataControllerId);
  }

  return (
    <Box>
      {orgTree.map((org) => (
        <OrgSubTree
          org={org}
          depth={0}
          getDataControllerColor={getDataControllerColor}
        />
      ))}
    </Box>
  );
}

type OrgTreeChild = {
  label: string;
  value: string;
  type: "team" | "organisation";
  dataControllerId?: string;
  isUserManagementOrganisation?: boolean;
  config?: Partial<OrganisationConfiguration>;
  children: OrgTreeChild[];
};

function OrgSubTree({
  org,
  depth,
  getDataControllerColor,
  parentColor,
}: {
  org: OrgTreeChild;
  depth: number;
  getDataControllerColor: (dataControllerId: string) => string | undefined;
  parentColor?: string;
}) {
  const color =
    org.type === "organisation"
      ? getDataControllerColor(org.dataControllerId ?? "")
      : parentColor;

  return (
    <Box sx={{ marginLeft: depth * 2 }}>
      <Box sx={{ display: "flex" }}>
        {org.type === "organisation" ? (
          <FiberManualRecord sx={{ color: color }} />
        ) : (
          <PlayArrow sx={{ color: color }} />
        )}
        <Typography
          sx={{
            fontWeight: depth === 0 ? "bold" : "",
            textDecoration:
              org.value === org.dataControllerId ? `underline ${color}` : "",
          }}
        >
          {org.label}
        </Typography>
        {org.isUserManagementOrganisation && (
          <Tooltip title="User Management Org">
            <Groups sx={{ marginLeft: 1 }} />
          </Tooltip>
        )}
        {org.config?.rioInstancesEnabled?.length === 1 && (
          <Tooltip title={org.config.rioInstancesEnabled[0].displayName}>
            <CloudDownload sx={{ marginLeft: 1 }} />
          </Tooltip>
        )}
      </Box>
      {org.children.map((child) => (
        <OrgSubTree
          org={child}
          depth={depth + 1}
          getDataControllerColor={getDataControllerColor}
          parentColor={color}
        />
      ))}
    </Box>
  );
}

function OrganisationCreateEditPageInner({
  organisationData,
  organisationId,
  refetchOrg,
  tab,
  setTab,
  orgTree,
}: {
  organisationId: string;
  organisationData: GetOrganisationResponse | null;
  refetchOrg: () => void;
  tab: number;
  setTab: (tab: number) => void;
  orgTree: OrgTree;
}) {
  const organisation = organisationData?.organisation || null;

  const isUserManagementOrganisation =
    organisationData?.organisation.isUserManagementOrganisation ?? false;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {organisationData ? (
        <h2>
          {organisation?.name} ({organisation?.role}){" "}
        </h2>
      ) : (
        <h2>Create New Organisation</h2>
      )}

      <Box sx={{ mb: 2 }}>
        <Tabs value={tab} onChange={(e, v) => setTab(v)} variant="scrollable">
          <Tab key={"Basic Details"} label={"Basic Details"} />
          <Tab
            key={"Child Orgs / Teams"}
            label={`Children (${
              organisation?.childOrganisations.length || 0
            }) / Teams (${organisation?.teams.length || 0})`}
            disabled={!organisationData}
          />
          <Tab
            key={"Organisation Members"}
            label={`Members (${organisation?.members.length || 0})`}
            disabled={!organisationData}
          />
          <Tab
            key={"Data Controller"}
            label={`Data Controller (${
              organisation?.orgsInThisDataController.length || 0
            })`}
            disabled={!organisationData}
          />
          <Tab
            key={"Configuration"}
            label={"Configuration"}
            disabled={!organisationData}
          />
          <Tab
            key={"Org Tree"}
            label={"Org Tree"}
            disabled={!organisationData || !orgTree}
          />
          {isUserManagementOrganisation && (
            <Tab key={"Users"} label={"Users"} />
          )}
        </Tabs>
      </Box>
      {tab === 0 && (
        <OrganisationCreateEditForm
          organisation={organisation}
          organisationId={organisationId!}
          refetchOrg={refetchOrg}
        />
      )}

      {tab === 1 && (
        <ChildOrgsAndTeamsEdit
          organisationData={organisationData!}
          organisation={organisation!}
        />
      )}

      {tab === 2 && (
        <OrgMembersEdit
          organisation={organisation}
          organisationId={organisationId!}
          refetchOrg={refetchOrg}
        />
      )}

      {tab === 3 && (
        <DataControllerInfo
          organisationData={organisationData!}
          organisation={organisation!}
        />
      )}

      {tab === 4 && (
        <OrganisationConfigurationEdit
          organisationData={organisationData!}
          refetchOrg={refetchOrg}
        />
      )}

      {tab === 5 && <OrganisationTree orgTree={orgTree} />}

      {tab === 6 && isUserManagementOrganisation && (
        <ManageOrganisationUsers
          organisationData={organisationData!}
          refetchOrg={refetchOrg}
        />
      )}
    </Box>
  );
}

export function OrganisationCreateEdit({}: {}) {
  let { organisationId } = useParams();

  const [tab, setTab] = useState(0);

  const [{ data: organisation, loading: orgLoading, response }, refetchOrg] =
    apiHooks.organisations.get(organisationId!);

  const [{ data: orgsAndTeams, loading: orgsAndTeamsLoading }] =
    apiHooks.reports.availableOrgsAndTeams({ orgId: organisationId! });

  return orgLoading || orgsAndTeamsLoading ? (
    <></>
  ) : (
    <OrganisationCreateEditPageInner
      tab={tab}
      setTab={setTab}
      organisationId={organisationId!}
      organisationData={response?.status === 200 ? organisation! : null}
      refetchOrg={refetchOrg}
      orgTree={orgsAndTeams!.orgTree}
    />
  );
}
