import {
  AuditTrailEntry,
  AuditTrailEntryType,
  ChoiceDifferencesEntry,
  cto1V1Base,
  cto5V1Base,
  cto7V1Base,
  DischargeReason,
  ExtendedThalamosUser,
  h3V1Base,
  h5V1Base,
  MhaStatus,
  s23V1Base,
} from "@aspire/common";
import { Box, CircularProgress, DialogContent } from "@mui/material";
import {
  PopupDialog,
  PopupDialogTitle,
} from "~/components/design-system/index.js";
import { formatMhaStatus } from "~/components/util.js";
import { ukLocalFormatDate, ukLocalFormatDateTime } from "~/util.js";
import { apiHooks } from "../../../../api.js";
import { entries } from "./Entries.js";

export function generateMergeMessage(audit: AuditTrailEntry) {
  return `${audit?.data?.mergedPatient || "unknown"} was merged into ${
    audit?.data?.destinationPatient || "unknown"
  }. ${audit?.data?.reason ? `(Reason: ${audit.data.reason})` : ""}
  ${
    audit?.data?.reasonDescription
      ? `(reason description -  ${audit.data.reasonDescription})`
      : ""
  }`;
}

export function generateUnmergeMessage(audit: AuditTrailEntry) {
  return `${audit?.data?.destinationPatient || "unknown"} was unmerged from ${
    audit?.data?.mergedPatient || "unknown"
  }. ${audit?.data?.reason ? `(Reason: ${audit.data.reason})` : ""}
  ${
    audit?.data?.reasonDescription
      ? `(Reason description:  ${audit.data.reasonDescription})`
      : ""
  }`;
}

export function generateUpdatePatientMessage(audit: AuditTrailEntry) {
  const patientFieldChanges = audit?.data?.patientFieldChanges
    ? Object.values(audit?.data?.patientFieldChanges)
    : [];

  const updatedFields =
    patientFieldChanges.length > 0
      ? `Updated: patient ${patientFieldChanges.join(", ")} has been updated`
      : "Patient details were reviewed and not updated.";

  return updatedFields;
}

export function generateUpdatePatientStateWithManualEventMessage(
  audit: AuditTrailEntry,
) {
  if (audit?.data?.status === MhaStatus.NotDetained) {
    let dischargeReason = audit?.data?.dischargeReason;
    if (dischargeReason === DischargeReason.Section23) {
      dischargeReason = `discharged ${audit.data.dischargeReason}`;
    }
    if (dischargeReason === DischargeReason.HoldingPowersDischarge) {
      dischargeReason = `discharged from holding powers`;
    }
    if (dischargeReason === DischargeReason.HoldingPowersDischarge) {
      dischargeReason = `discharged by Tribunal.`;
    }
    return `MHA Status updated to ${
      audit?.data?.status
    }, Reason: ${dischargeReason ?? "Unknown"}`;
  }

  return `MHA Status manually input as ${formatMhaStatus(audit.data.status) ?? "Unknown"}${audit.data.startDateTime ? `, with a start date time of ${ukLocalFormatDateTime(audit.data.startDateTime)}` : ""}`;
}

export function generateAutomaticallyExpiredPatientStateMessage(
  audit: AuditTrailEntry,
) {
  return `This patient's MHA status ${audit.data.status ?? "Unknown"} expired at ${ukLocalFormatDateTime(audit.data.expiryDateTime)}`;
}

export function generateAutomaticallyPatientStateMessage(
  audit: AuditTrailEntry,
) {
  const formatStatus = formatMhaStatus(audit.data.status);
  if (audit.data.status === MhaStatus.Unknown) return null;

  if (audit.data.status === MhaStatus.Incomplete)
    return `MHA Status ${formatStatus ?? "Unknown"}, data is missing.`;

  if (audit.data.status === MhaStatus.Section3) {
    // Renewal
    if (audit.data.formTemplateId === h5V1Base.id) {
      return `Section 3 renewal of detention form (H5) signed, with a start date of ${ukLocalFormatDate(audit.data.startDateTime)}. Section 3 detention renewed until ${ukLocalFormatDate(audit.data.expiryDateTime)}.`;
    }
    // New Section 3 due to CTO revocation
    if (audit.data.formTemplateId === cto5V1Base.id) {
      return `MHA Status updated to ${formatStatus ?? "Unknown"}, revocation of community treatment (CTO5) signed.`;
    }
  }

  if (
    audit.data.formTemplateId === h3V1Base.id &&
    (audit.data.status === MhaStatus.Section4 ||
      audit.data.status === MhaStatus.Section2 ||
      audit.data.status === MhaStatus.Section3)
  ) {
    return `MHA Status updated to ${formatStatus ?? "Unknown"}, ${audit.data.status ?? "Unknown"} record of detention form (H3) signed.`;
  }

  if (audit.data.status === MhaStatus.Section17A) {
    // Initial CTO
    if (audit.data.formTemplateId === cto1V1Base.id) {
      return `Community Treatment Order form (CTO1) signed. CTO effective date of ${ukLocalFormatDate(audit.data.startDateTime)}.`;
    }
    // Extension
    if (audit.data.formTemplateId === cto7V1Base.id) {
      return `Extension of Community Treatment Order form (CTO7) signed, with a start date of ${ukLocalFormatDate(audit.data.startDateTime)}. CTO extended until ${ukLocalFormatDate(audit.data.expiryDateTime)}.`;
    }
  }

  if (audit.data.status === MhaStatus.NotDetained) {
    if (audit.data.formTemplateId === s23V1Base.id) {
      return `MHA Status updated to ${formatStatus ?? "Unknown"}, Section 23 form signed.`;
    }
    return `MHA Status updated to ${formatStatus ?? "Unknown"}, discharge from holding powers form signed.`;
  }

  return `MHA Status updated to ${formatStatus ?? "Unknown"}, ${formatStatus ?? "Unknown"} form signed.`;
}

export function generateUpdatePatientDemographicsPullEvent(
  audit: AuditTrailEntry,
) {
  if (audit.data.processed === true) {
    return null;
  } else {
    const system = audit.data.externalSystemType === "pds" ? "PDS" : "Rio";

    const rioInstanceName = audit.resources.filter(
      (r) => r.resourceType === "rioInstance",
    )?.[0]?.resourceName;

    let eventMessage = `Patient details synchronised with ${system}`;
    if (rioInstanceName !== undefined) {
      eventMessage += ` (${rioInstanceName})`;
    }
    eventMessage += ". Available updates were ignored/skipped:";

    const differences: string[] = [];
    if (audit.data.differences && Array.isArray(audit.data.differences)) {
      differences.push(
        ...audit.data.differences.map(
          (diff: Pick<ChoiceDifferencesEntry, "type">) => diff.type,
        ),
      );
    }

    if (differences.length > 0) {
      eventMessage += ` ${differences.join(", ")}`;
    }

    return eventMessage;
  }
}

function getMessageFn(typesToExclude: AuditTrailEntryType[]) {
  return (audit: AuditTrailEntry): string | string[] | null => {
    if (typesToExclude.includes(audit.type)) {
      return null;
    }

    switch (audit.type) {
      case AuditTrailEntryType.createPatient:
        return `Patient ${audit?.resources.find((r) => r.resourceType === "patient")?.resourceName} was created`;

      case AuditTrailEntryType.mergePatient:
        return generateMergeMessage(audit);

      case AuditTrailEntryType.unmergePatient:
        return generateUnmergeMessage(audit);

      case AuditTrailEntryType.updatePatient:
        return generateUpdatePatientMessage(audit);

      case AuditTrailEntryType.updatePatientStateWithManualEvent:
        return generateUpdatePatientStateWithManualEventMessage(audit);

      case AuditTrailEntryType.automaticallyExpirePatientState:
        return generateAutomaticallyExpiredPatientStateMessage(audit);

      case AuditTrailEntryType.updatePatientStateAutomatically:
        return generateAutomaticallyPatientStateMessage(audit);

      case AuditTrailEntryType.updatePatientDemographicsPullEvent:
        return generateUpdatePatientDemographicsPullEvent(audit);

      case AuditTrailEntryType.patientSearchReasoning:
        return `${
          audit?.data?.reason
            ? `
        Patient record accessed via search under the following reason: ${audit.data.reason}`
            : ""
        }
        ${
          audit?.data?.reasonDescription
            ? `- ${audit.data.reasonDescription}`
            : ""
        }`;

      case AuditTrailEntryType.createPatientDemographicsAlert:
        if (audit.data.type === "invalidated") {
          return "Updated: The NHS number on this record was no longer valid and was decoupled from the Spine/PDS.";
        }
        return null;

      case AuditTrailEntryType.clearPatientDemographicsAlerts:
        return "Patient demographics alerts were cleared";
      case AuditTrailEntryType.createSuggestedPatientMerge:
        return "A suggested patient merge was created for this patient (marked as superseded on Spine)";
      case AuditTrailEntryType.actionSuggestedPatientMerge:
        return "A suggested patient merge was actioned by a demographics manager";
      case AuditTrailEntryType.patientSupersededNhsNumberUpdated:
        return "Updated: The NHS number on this record was replaced (superseded on Spine) ";
      case AuditTrailEntryType.manuallyOverridePatientStateExpiryDate:
        if (audit.data.reason) {
          return `MHA status end date manually edited to ${ukLocalFormatDateTime(audit.data.expiryDateTime)}, for the following reason: ${audit.data.reason}. Additional information provided: ${audit.data.reasonDescription}`;
        } else {
          return `MHA status end date manually reset to default value.`;
        }
      default:
        return null;
    }
  };
}

export function PatientActivityLog({
  patientId,
  user,
  onClose,
}: {
  patientId: string;
  user: ExtendedThalamosUser;
  onClose: () => void;
}) {
  const [{ data: auditData, loading: auditDataLoading }] =
    apiHooks.audit.searchByPatientId(patientId, {
      limit: 1000,
      offset: 0,
      fromDate: "2000-01-01",
      toDate: "2050-01-01",
    });

  const patientMhaStatusEnabled =
    user.sessionOrganisationConfiguration?.patientStateEnabled;

  const patientSearchRecordReasonsEnabled =
    user.sessionOrganisationConfiguration?.patientSearchRecordReasonsEnabled;

  return (
    <PopupDialog open={true} onClose={onClose} fullWidth>
      <PopupDialogTitle
        titleText={`Activity Log for patient`}
        closeDialog={onClose}
      />
      <DialogContent>
        {auditDataLoading ? (
          <CircularProgress />
        ) : (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {entries(
              user,
              auditData?.entries.reverse()!,

              // Exclude MHA status related entries if patientMhaStatusEnabled is false
              getMessageFn([
                ...(patientSearchRecordReasonsEnabled
                  ? []
                  : [AuditTrailEntryType.patientSearchReasoning]),
                ...(patientMhaStatusEnabled
                  ? []
                  : [
                      AuditTrailEntryType.updatePatientStateAutomatically,
                      AuditTrailEntryType.updatePatientStateWithManualEvent,
                      AuditTrailEntryType.automaticallyExpirePatientState,
                    ]),
              ]),
            )}
          </Box>
        )}
      </DialogContent>
    </PopupDialog>
  );
}
